body {
  margin: 0;
  font-size: 18px !important;
  font-family: "Cabin", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.offcanvas {
  max-width: 75% !important;
  bottom: 20% !important;
  border-bottom-left-radius: 20px;
}

.print-show {
  display: none;
}
