.progressbar-wrapper {
  background: #fff;
  height: 80px;
  padding: 1rem 0;
  margin: 0 auto;
}

.progressbar {
  display: flex;
  margin: 0;
  padding: 0;
}

.progressbar li {
  flex: 1;
  list-style-type: none;
  font-size: 1rem;
  font-weight: 750;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: var(--muted-text-color);
  /* line-height: 3rem; */
}

.progressbar li:before {
  width: 20px;
  height: 20px;
  content: "";
  line-height: 20px;
  display: block;
  text-align: center;
  margin: 0 auto 3px auto;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background-color: #ccc;
}
.progressbar li:after {
  width: 100%;
  height: 4px;
  content: "";
  position: absolute;
  background-color: #ccc;
  top: 8px;
  left: -50%;
  z-index: 0;
}
.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: var(--text-color);
}
.progressbar li.active:before {
  background: var(--primary-color);
}
.progressbar li.active:after {
  background-color: var(--primary-color);
}
