.service-item {
  border-bottom: 1px solid var(--border-color);
  padding: 1rem;
  padding-bottom: 1.25rem;
}

.service-item img {
  max-height: 35px;
}

.service-item__sublist {
  position: relative;
  margin: 1rem -3rem;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.service-item__sublist__close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  text-decoration: none;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  line-height: 1rem;
}

@media (max-width: 768px) {
  .service-item__sublist {
    margin: 1rem -1rem;
  }
}

.form-check-input:checked {
  background-color: #205B68;
  border-color: #205B68;
}

@media only screen and (max-width: 430px) {
  .service-item {
    padding: 1rem 0.25rem;
  }
}